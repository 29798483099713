<script lang="ts">
    import { createEventDispatcher } from 'svelte';
    import * as Dialog from '$lib/components/ui/dialog';

    export let title;
    export let onConfirm;
    export let confirmText = 'Confirm';

    const dispatch = createEventDispatcher();

    const handleConfirm = () => {
        onConfirm();
        dispatch('close');
    };
</script>

<Dialog.Root on:close>
    <Dialog.Trigger>
        <slot name="trigger"></slot>
    </Dialog.Trigger>
    <Dialog.Portal>
        <Dialog.Overlay class="bg-black bg-opacity-10" />
        <Dialog.Content class="bg-white">
            <div>
                <Dialog.Header>
                    <Dialog.Title>
                        {title}
                    </Dialog.Title>
                </Dialog.Header>
                <div class="mt-3">
                    <slot></slot>
                </div>
                <div class="mt-3 flex items-center justify-end gap-x-2">
                    <Dialog.Close>
                        <button
                            type="button"
                            class="inline-flex items-center gap-x-2 rounded-lg border border-transparent bg-bmn-600 px-3 py-2 text-sm font-semibold text-white hover:bg-bmn-700 dark:bg-bmn-500"
                            on:click="{handleConfirm}"
                        >
                            {confirmText}
                        </button>
                    </Dialog.Close>
                </div>
            </div>
        </Dialog.Content>
    </Dialog.Portal>
</Dialog.Root>
